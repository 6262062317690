<template>
  <section>
    <b-card>
      <h3 class="mb-1 font-weight-bolder">
        {{ $t("Global.Filters") }}
      </h3>
      <b-row>
        <b-col lg="3" md="4" sm="12">
          <label>
            {{ $t("Management.Processes.ProcessType") }}
          </label>
          <v-select
            v-model="filter.processType"
            :placeholder="$t('Global.Filter')"
            :options="ProcessTypes"
          >
          <div slot="no-options">{{ $t("Global.NothingFound") }}</div>
            <template #option="{ label }">
              <div>{{ $t(`Management.Processes.ProcessTypes.${label}`) }}</div>
            </template>
            <template #selected-option="{ label }">
              <div>{{ $t(`Management.Processes.ProcessTypes.${label}`) }}</div>
            </template>
          </v-select>
        </b-col>
        <b-col lg="3" md="4" sm="12">
          <label>
            {{ $t("Management.Accounting.Shareholder") }}
          </label>
          <v-select
            v-model="filter.shareholder"
            :placeholder="$t('Global.Filter')"
            :options="Shareholder"
          >
          <div slot="no-options">{{ $t("Global.NothingFound") }}</div>
            <template #option="{ label }">
              <div>{{ $t(`Management.Processes.Shareholders.${label}`) }}</div>
            </template>
            <template #selected-option="{ label }">
              <div>{{ $t(`Management.Processes.Shareholders.${label}`) }}</div>
            </template>
          </v-select>
        </b-col>
        <b-col lg="3" md="4" sm="12">
          <label>
            {{ $t("Management.Accounting.Month") }}
          </label>
          <v-select
            v-model="filter.month"
            :placeholder="$t('Global.Filter')"
            :options="months"
          >
          <div slot="no-options">{{ $t("Global.NothingFound") }}</div>
            <template #option="{ label }">
              <div>{{ $t(`Management.Accounting.Months.${label}`) }}</div>
            </template>
            <template #selected-option="{ label }">
              <div>{{ $t(`Management.Accounting.Months.${label}`) }}</div>
            </template>
          </v-select>
        </b-col>
        <b-col lg="3" md="4" sm="12">
          <label>
            {{ $t("Management.Accounting.Year") }}
          </label>
          <v-select
            v-model="filter.year"
            :placeholder="$t('Global.Filter')"
            :options="harvestYears"
          >
          <div slot="no-options">{{ $t("Global.NothingFound") }}</div>
          </v-select>
        </b-col>
      </b-row>
    </b-card>
    <b-card no-body>
      <div
        class="custom-search d-flex justify-content-between align-items-center mb-2 px-2 pt-2"
      >
        <h2 class="m-0 font-weight-bolder">
          {{ $t("Global.All") }} {{ $t("Management.Accounting.Accountings") }}
        </h2>
        <div class="d-flex">
          <div class="d-flex align-items-center">
            <b-form-input
              v-model="searchTerm"
              :placeholder="$t('Global.Search')"
              type="text"
              class="d-inline-block search"
            />
          </div>
        </div>
      </div>
      <Table :rows="rowData" :search-term="searchTerm" @delete="deleteRow" />
    </b-card>
  </section>
</template>

<script>
import { BFormInput, BCard, BRow, BCol, BButton } from "bootstrap-vue";
import Table from "./components/table.vue";
import deleteEntry from "@/utility/scripts/delete";
import { ProcessTypes, Shareholder } from "@/data/enums";
import vSelect from "vue-select";

export default {
  components: {
    BFormInput,
    BCard,
    Table,
    vSelect,
    BRow,
    BCol,
    BButton,
  },

  data: () => ({
    searchTerm: "",
    rows: [],
    harvestYears: [],
    months: [
      "January",
      "February",
      "March",
      "April",
      "May",
      "June",
      "July",
      "August",
      "September",
      "October",
      "November",
      "December",
    ],
    Shareholder,
    ProcessTypes,
    filter: {
      shareholder: null,
      month: null,
      year: null,
      processType: null,
    },
    shareholder: null,
    month: null,
    year: null,
    processType: null,
  }),

  computed: {
    rowData() {
      return this.filterByProcessType(this.filterByShareholder(this.filterByYear(this.filterByMonth(this.rows))));
    },
  },

  created() {
    this.load();
  },

  methods: {

    filterByShareholder(rows){
      return rows.filter(x => {
        if (this.filter.shareholder === null) return true
        return this.filter.shareholder === x.shareholder
      });
    },

    filterByMonth(rows){
      return rows.filter(x => {
        if (this.filter.month === null) return true
        return this.filter.month === x.month
      });
    },

    filterByProcessType(rows){
      return rows.filter(x => {
        if (this.filter.processType === null) return true
        return this.filter.processType === x.processType
      });
    },

    filterByYear(rows){
      return rows.filter(x => {
        if (this.filter.year === null) return true
        return this.filter.year === x.year
      });
    },
    
    async load() {
      try {
        this.populateHarvestYears();
        this.getInvoices();
      } catch (error) {
        console.error(error);
      }
    },

    async getInvoices() {
      try {
        const { data } = await this.$axios.get(`invoices`);
        this.rows = data;
      } catch (error) {
        this.$alert.error();
      }
    },

    addEntry(entry) {
      this.rows.push(entry);
    },

    populateHarvestYears() {
      let year = new Date(Date.now()).getFullYear();
      for (
        year;
        year >= 2006;
        year--
      ) {
        this.harvestYears.push(year.toString());
      }
    },

    async create() {
      this.$router.push({
        name: "accounting/invoice-create",
        params: {
          shareholder: this.shareholder,
          month: this.month,
          year: this.year,
          processType: this.processType,
        },
      });
    },
    searchDisabled() {
      return (
        (this.shareholder === null) | (this.month === null) ||
        this.year === null
      );
    },

    async deleteRow(row) {
      try {
        await deleteEntry(this, {
          id: row.id,
          subject: `${this.$t("Management.Accounting.Accounting")}`,
          individual: `"${row.shareholder} - ${row.month}/${row.year}"`,
          endpoint: "invoices/",
        });
        this.load();
      } catch (error) {
        // continue regardless of error
      }
    },
  },
};
</script>
<style lang="scss">
.buttons-container {
  gap: 1rem;
}
</style>
